import type React from "react";

import { type ComponentProps, ImagePlaceholder } from "@Components";
import { gallerySizeContainer } from "../utils";

interface GalleryGridPlaceholderProps extends ComponentProps {}

export const GalleryGridPlaceholder: React.FC<
	GalleryGridPlaceholderProps
> = () => (
	<div
		sx={{
			position: "relative",
			overflow: "hidden",
			borderRadius: [0, "8"],
			...gallerySizeContainer,
		}}
	>
		<div
			sx={{
				position: "relative",
				paddingBottom: ["64%", "50%"],
			}}
		>
			<ImagePlaceholder
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					...gallerySizeContainer,
				}}
			/>
		</div>
	</div>
);
